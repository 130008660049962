import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import HubCard from "../components/hubcard"

const GiftShop = ({data}) => (

  <Layout>
    <SEO title="Gift Shop" />
  
    <PostHero
        title="Gift Shop"
        description="Explore our educational resources and merchandise"
        image={require('../images/pexels-chan-walrus-941864.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">

                {data.allContentfulCard.edges.map(edge => (
                    <HubCard
                        key={edge.node.id}
                        image={edge.node.image.fluid.src}                        
                        title={edge.node.title}    
                        contenttype={edge.node.contentType.title}
                        url={edge.node.url}
                        desc={edge.node.description.childMarkdownRemark.html}
                        alttext={edge.node.image.title}
                    />
                ))}

            </div>

         </div>

      </div>

  </Layout>
)

export default GiftShop


export const query = graphql`
{
    allContentfulCard(sort: {fields: createdAt, order: DESC}, filter: {giftShop: {eq: true}}) {
        edges {
            node {
                title       
                url
                contentType {
                    title
                }
                description {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    title
                    fluid(maxWidth: 1200, quality: 85) {
                        src
                        ...GatsbyContentfulFluid
                    }
                }
                spiritType {
                    title
                }
                id
                createdAt        
            }
        }
    }
}
`
